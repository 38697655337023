import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView1.vue"),
  },
  {
    path: "/login",
    name: "loginPage",
    component: () => import("../views/LoginPage.vue"),
  },
  {
    path: "/park2",
    name: "park2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/HomeView2.vue"),
  },
  {
    path: "/park3",
    name: "park3",
    component: () => import("../views/HomeView3.vue"),
  },
  {
    path: "/park4",
    name: "park4",
    component: () => import("../views/HomeView4.vue"),
  },
  {
    path: "/park5",
    name: "park5",
    component: () => import("../views/HomeView5.vue"),
  },
  {
    path: "/stats",
    name: "stats",
    component: () => import("../views/StatsView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem("token");
  if (!token && to.name !== "loginPage") {
    next({ name: "loginPage" });
  } else if (token && to.name === "loginPage") {
    next({ name: "home" });
  } else {
    next();
  }
});

export default router;
