import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css"; //引入全部的样式
import "view-design/dist/styles/iview.css";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;
// Vue.use(ViewUI);
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
